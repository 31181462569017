import React from "react";
import { Link, navigate } from "gatsby";
import { graphql } from "gatsby";

//NPM
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import Loader from "react-loader-spinner";

//Components
import Layout from "../components/layout";
import SEO from "../components/seo";
import Jumbotron from "../components/jumbotron";
import SectionTitle from "../components/section-title";
import EventCard from "../components/event-card";
import Button from "../components/button";
import GalleryCard from "../components/gallery-card";
import moment from "moment";
import ShopBanner from "../components/shop-banner";
import { Center, Flex, Space } from "@mantine/core";
import { colors } from "../colors/colors";

const CenterBlock = styled.div`
  text-align: center;
  display: block;
  margin: 0 auto;
`;

export const EventsContainer = styled.div`
  display: grid;
  max-width: 1080px;
  grid-gap: 15px;
  row-gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  margin: 0 auto;

  @media (max-width: 1080px) {
    padding: 0 15px;
  }

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;
export const GalleryContainer = styled.div`
  display: grid;
  max-width: 900px;
  grid-gap: 10px;
  row-gap: 40px;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  margin: 0 auto;
  margin-bottom: 40px;

  @media (max-width: 900px) {
    padding: 0 15px;
  }

  @media (max-width: 550px) {
    /* grid-template-columns: 1fr; */
    grid-template-columns: ${(props) =>
      props.video ? `1fr` : `repeat(2, 1fr)`};
  }
`;

class IndexPage extends React.PureComponent {
  state = {
    events: [],
    albums: [],
    loggedIn: null,
  };

  componentWillMount() {
    this.setState({ loggedIn: true });
    // CurrentUserAsync().then(res => {
    // 	if (res) {
    // 		this.setState({ loggedIn: true })
    // 	} else {
    // 		this.setState({ loggedIn: false })
    // 		navigate('/login')

    // 	}
    // })
  }

  componentDidMount() {
    //Function to order by date
    const data = this.props.data.events.edges;
    const monthsAway = moment().add("months", 3).toDate();
    const sortedData = data.filter(function (event) {
      //Return events withinn the next 3 months
      return (
        new Date(event.node.acf.date) < monthsAway &&
        new Date(event.node.acf.date) > moment().toDate()
      );
    });
    const orderedEvents = sortedData.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.node.acf.date) - new Date(a.node.acf.date);
    });

    this.setState({ events: orderedEvents.reverse() });

    const albums = this.props.data.media.edges;
    const sortedData2 = albums.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.node.acf.event_date) - new Date(a.node.acf.event_date);
    });

    this.setState({ albums: sortedData2 });
  }

  render() {
    if (!this.state.loggedIn) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <CenterBlock>
            <Loader type="ThreeDots" color="#B4271D" height="50" width="50" />
          </CenterBlock>
        </div>
      );
    }

    const data = this.props.data.allWordpressPage.edges[0].node.acf;
    const { events, albums } = this.state;
    console.log({ events });
    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[
            `yup`,
            `life`,
            `FOC`,
            `Festival`,
            `Colour`,
            `Backyard`,
            `Melee`,
            `Events`,
            `Fete`,
            `Trinidad`,
            `Carnival`,
            `Party`,
            `Fete`,
          ]}
        />
        <Jumbotron slides={data?.jumbotron_gallery ?? []} />
        <SectionTitle
          title="Upcoming Events"
          subtitle="See what makes the YUP Life experience so special"
        />

        <EventsContainer>
          {events.map((event) => (
            <EventCard
              key={event.node.id}
              flyerUrl={event.node.acf.flyer.source_url}
              title={event.node.acf.name}
              date={event.node.acf.date}
              slug={event.node.slug}
              fluid={event.node.acf.flyer.localFile.childImageSharp.fluid}
            />
          ))}
        </EventsContainer>
        {events.length === 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                color: colors.copy,
                textAlign: "center",
                opacity: 0.8,
                fontFamily: "Poppins",
              }}
            >
              No upcoming events. Stay tuned for more!
            </p>
          </div>
        )}
        <ShopBanner image={data?.merch_image} />
        <SectionTitle
          title="Featured Albums"
          subtitle="Experience vicariously a YUPLife event, through our"
        />

        <GalleryContainer>
          <>
            {albums.map((album, index) => (
              <Fade key={index} bottom distance={"10px"}>
                <div
                  onClick={() =>
                    navigate(`/media`, {
                      state: {
                        tag: album.node.acf.tag,
                        name: album.node.acf.event_name,
                      },
                    })
                  }
                >
                  <GalleryCard
                    title={album.node.acf.event_name}
                    alt={album.node.acf.cover.alt_text}
                    url={album.node.acf.cover.source_url}
                    tag={album.node.acf.tag}
                    count={album.node.acf.image_count}
                  />
                </div>
              </Fade>
            ))}
            {/* <Indicator /> */}
          </>
        </GalleryContainer>
        <Link to="/media">
          <Button title="Browse Media" />
        </Link>
      </Layout>
    );
  }
}

export default IndexPage;

export const homeQuery = graphql`
  query (
    $homepage: String = "Home"
    $media: String = "Album"
    $events: String = "Event"
  ) {
    allWordpressPage(filter: { title: { eq: $homepage } }, limit: 3) {
      edges {
        node {
          title
          acf {
            jumbotron_event
            jumbotron_subtitle
            jumbotron_gallery {
              url
              image {
                source_url
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxHeight: 2000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            jumbotron_cover {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxHeight: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            merch_image {
              source_url
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxHeight: 2000) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    media: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $media } } } }
      limit: 4
    ) {
      edges {
        node {
          title
          acf {
            event_name
            image_count

            cover {
              source_url
              alt_text
            }
            tag
            upload_date
            event_date
            hide
          }
        }
      }
    }
    events: allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: $events } } } }
    ) {
      edges {
        node {
          title
          id
          content
          slug
          acf {
            name
            flyer {
              source_url
              localFile {
                childImageSharp {
                  fluid(maxHeight: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            date
            ticket_url
            hide
            tagline
          }
        }
      }
    }
  }
`;
