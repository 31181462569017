import { Flex } from "@mantine/core";
import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";
import { FaPlayCircle } from "react-icons/fa";

const ImageContainer = styled.div`
  width: 100%;
  cursor: pointer;
  height: 185px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* @media (max-width: 600px) {
    height: 130px;
    margin: 5px;
    width: 160px;
  } */

  /* @media (max-width: 360px) {
    height: 120px;
    margin: 5px;
    width: 130px;
  } */
`;
const GalleryImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  /* position: absolute; */
  transform: scale(1.1);
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

  ${ImageContainer} :hover & {
    transform: scale(1);
    transform: translateY(-2px);
    filter: brightness(0.6);
  }
`;
const Title = styled.h1`
  color: ${colors.copy};
  font-size: 17px;
  font-family: "Tanker";
  transition: 0.4s ease-in-out;
  font-weight: 400;
  pointer-events: none;
  text-align: center;
  transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: 8px;
  width: auto;
  margin-bottom: 0;

  ${ImageContainer}:hover & {
    transform: scale(1.05);
    transform: translateY(-5px);
  }
`;

const GalleryCard = (props) => (
  <Flex style={{ width: "100%" }} direction="column" align="center">
    <ImageContainer>
      {props.isVideoCover && (
        <div style={{ position: "absolute", zIndex: 2 }}>
          <FaPlayCircle size={50} color="white" style={{ opacity: 0.8 }} />
        </div>
      )}
      <GalleryImage alt={props.alt} src={props.url} />
    </ImageContainer>
    <Title dangerouslySetInnerHTML={{ __html: props.title }} />
  </Flex>
);

export default GalleryCard;
