import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { colors } from "../colors/colors";
import Button from "./button";
import Img from "gatsby-image";

export default function ShopBanner({ image }) {
  console.log({ image });
  return (
    <Grid>
      <Content>
        <h2>Step out in style</h2>
        <p>Shop our 2023 Merch Collection now</p>
        <Link to="/shop">
          <Button title="See what's in store" />
        </Link>
      </Content>
      <Image>
        <Img
          style={{
            width: "100%",
            height: "100%",
            margin: 0,
          }}
          fadeIn
          objectFit="cover"
          fluid={image.localFile.childImageSharp.fluid}
        />
        {/* <img src={require("../images/merch.jpg")} /> */}
      </Image>
    </Grid>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  color: ${colors.copy};
  align-items: center;
  padding: 15vh 0;

  @media (max-width: 900px) {
    padding: 12vh 20px;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
    padding: 8vh 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  order: 1;
  h2 {
    font-family: "Tanker";
    font-size: 3.5rem;
    margin-bottom: 0;
  }
  p {
    font-size: 1.1rem;
    font-family: "Poppins";
  }

  @media (max-width: 550px) {
    align-items: center;
    text-align: center;
    order: 2;
    h2 {
      font-size: 2.5rem;
    }
    p {
      font-size: 1rem;
      font-family: "Poppins";
    }
  }
`;

const Image = styled.div`
  order: 2;

  @media (max-width: 550px) {
    order: 1;
  }
  img {
    width: 100%;
    max-height: 85vh;
    object-fit: cover;
  }
`;
