import React, { Component } from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import moment from "moment";
import { colors } from "../colors/colors";

const EventCardContainer = styled.div`
  text-align: center;
  position: relative;
  color: white;
  width: 100%;
  cursor: pointer;
  scroll-snap-align: center;
  justify-self: center;
`;

const EventImage = styled.div`
  aspect-ratio: 1/1;
  border-radius: 2px;
  margin-bottom: 8px;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
`;
const EventTitle = styled.h1`
  font-family: "Tanker";
  font-size: 1.3rem;
  margin: 0 auto;
  margin-bottom: 4px;
  text-align: center;
  color: ${colors.copy};
  opacity: 0.9;
  line-height: 1.2;
  font-weight: 400;
`;
const EventDate = styled.p`
  color: ${colors.red};
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  margin: 0;
`;

class EventCard extends Component {
  render() {
    const link = `/events/${this.props.slug}`;
    return (
      <EventCardContainer onClick={() => navigate(link)}>
        <EventImage>
          <Img
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
            }}
            fadeIn
            objectFit="cover"
            fluid={this.props.fluid}
          />
        </EventImage>
        <EventTitle>{this.props.title}</EventTitle>
        <EventDate>
          {moment(this.props.date).format("MMMM Do, YYYY")}{" "}
          {this.props.title === "Bacchanal Weekend (Combo)" &&
            "- February 9th, 2020"}
        </EventDate>
        {/* <Link to={link}>
          <Button title="View Details" />
        </Link> */}
      </EventCardContainer>
    );
  }
}

export default EventCard;
